.system {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 15px !important;
  margin-bottom: 17px;
}

.logs-table {
  font-size: smaller;
}

.logs-table-body {
  font-family: monospace;
}

.logs-table-message {
  word-break: break-all;
}

.logs {
  min-height: 100px;
}

.no-grow {
  white-space: nowrap;
  overflow: overlay;
}

.edit-code-header {
  margin-top: 1em !important;
  font-size: initial !important;
}

.edit-code-content {
  padding: 0px !important;
}

.debug-view-content, .share-ls-content {
  padding: 5px !important;
}

.left-align {
  text-align: left;
}

.footer-buttons {
  margin-top: 14px;
  text-align: right;
}

.header-buttons {
  margin-bottom: 14px;
  text-align: right;
}

.edit-code-container, .edit-code-container-error {
  overflow: auto;
}

.share-count-column {
  width: 110px;
}

.share-scan-detail {
  margin-top: 10px;
  font-size: 14px;
}

.transfer-header {
  margin-bottom: -15px !important;
}