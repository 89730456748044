body {
    background: #c8c8c8 !important;
}

.ui.table td, th {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.ui.inverted.menu .item:before {
    background: rgba(255,255,255, 0);
}

.app {
    margin: auto;
    border: 0px !important;
    border-radius: 0% !important;
    box-shadow: none !important;
}

.app-content {
    margin-top: 70px;
    background: #c8c8c8 !important;
}

.navigation {
    overflow: overlay;
}

.result-card {
    width: 100% !important;
}

.search-list-card {
    width: 100% !important;
}

.result-card:last-child, .transfer-card:last-child, .search-list-card:last-child {
    margin-bottom: 17px !important;
}

.result-meta {
    margin-top: 5px;
}

@media only screen and (min-width: 768px) {
    .transferlist-progress {
        max-width: 300px;
        width: 300px;
    }
}

@media only screen and (max-width: 767px) {
    .transferlist-progress {
        width: 100%;
    }
}

.filelist-header {
    margin-top: 20px !important;
}

.filelist-selector, .transferlist-selector, .list-selector {
    width: 40px;
}

.filelist-filename {
    word-wrap: break-word !important;
}

.filelist-size {
    width: 100px;
}

.filelist-attributes {
    width: 115px;
}

.filelist-length{
    width: 65px;
}

.showmore-button {
    padding: 20px !important;
    max-width: 1200px !important;
    margin-bottom: 17px !important;
}

.login-button {
    margin-top: 0px !important;
}

.login-failure {
    text-align: left;
}

.close-button {
    float: right;
    margin-right: 0px !important;
    font-size: 16pt !important;
    line-height: 1.15 !important;
}

.placeholder-segment, .placeholder-segment-small {
    opacity: .25;
}

.placeholder-segment-small {
    min-height: 6rem !important;
}

.placeholder-segment-small > .ui.icon.header {
    font-size: 1em !important;
}

.placeholder-segment-small > .ui.icon.header > i {
    font-size: 2em !important;
}


.menu-icon-group {
    font-size: 1.71428571em !important;
    margin: 0 auto .5rem !important;
    text-shadow: unset !important;
}

.menu-icon-no-shadow {
    font-size: 1rem !important;
    text-shadow: unset !important;
}

.loader-segment.ui.placeholder > :before {
    background-color: transparent !important;
}

.view {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
  
.view:last-child {
  margin-bottom: 17px;
}

/* https://github.com/Semantic-Org/Semantic-UI-React/issues/3133#issuecomment-418766748 */
.ui.dimmer .ui.modal-loader.loader:before {
  border-color: rgba(0,0,0,.1);
}
        
.ui.dimmer .ui.modal-loader.loader:after {
  border-color: #767676 transparent transparent;
}